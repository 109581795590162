// Import necessary hooks and libraries
import './style/App.css'
import { useState, useEffect } from 'react';
import { database } from './Firebase';
import { ref, onValue, off } from "firebase/database";
import pfp from './images/pfp.webp';
import flex_logo from './images/flex_logo.png';
import { Link } from "react-router-dom";
import { useWeb3Modal } from '@web3modal/react'
import { useAccount } from 'wagmi'
import  sliceWallet  from "./components/SliceWallet"

//Starting app functions
function Menu() {
  
  // Open and close wallet connect modal
  const { open } = useWeb3Modal()

  // Get address from wallet connect
  const { address } = useAccount();

   // State variables for random image generation
  const [num, setNum] = useState(Math.floor(Math.random() * 5000) + 1);
  const [randomImg, setRandomImg] = useState(pfp);

   // Function to generate random number in range
  function randomNumberInRange(min, max) {    
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Periodically generate a new random number and fetch a corresponding image from the database
  useEffect(() => {
    const interval = setInterval(() => {
      // 👇️ generate random number
      setNum(randomNumberInRange(1, 5000));
    }, 10000);  
  
  const dbRef = ref(database, 'id/' + num + 'details/imgURL');
  const snapshotCallback = (snapshot) => {
    const data = snapshot.val();
      if (data !== null) {
        setRandomImg(data);
      }
    };
    onValue(dbRef, snapshotCallback);

    // Clean up interval and database listener on unmount
    return () => {
      clearInterval(interval);
      off(dbRef, "value", snapshotCallback);
    };
  }, [num]); 
 
 
  return ( 
  
  // Render the menu
  <div className="menu-main-div">    
    <Link to='/'>
    <div className='menu-logo-img' alt='logo'>      
      <img src={flex_logo} width='80' alt='flex721'/>     
    </div>
    </Link> 

    <div className="random-number">
        ハシ{num}
    </div>  

    <div className='random-flexness-div'>      
      <img className='random-flexness-pic' src={randomImg} alt=""></img>
    </div>

    <div className="menu">
      <nav>
        <Link to="/" className='home-button'>home</Link> 
        { address ? <Link to="/dashboard">dashboard</Link> : (<button className='connect-wallet-button2' onClick={() => open()}>connect wallet</button>)}       
        {/* (<button className='connect-wallet-button1' onClick={() => open()}>{sliceWallet(address)}</button>)  */}        
        <Link to="/drops">drops</Link>
        <Link to="/about">about</Link>        
        <a href="https://www.twitter.com/flex_721" target="_blank" rel="noopener noreferrer" className='twitter-link'>twitter</a>        
      </nav>
    </div>
    {/* <div className='menu-footer'>      
      <Link to='/affiliates'>affiliates</Link>      
    </div>        */}
  </div>
  );
}


export default Menu