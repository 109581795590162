import './style/App.css';
import React, { useEffect, useState } from "react";
import pfp from './images/pfp.webp';
import Resizer from "react-image-file-resizer";
import flex_logo from './images/flex_logo.png';
import sliceWallet from "./components/SliceWallet"
import { AiOutlineUpload } from 'react-icons/ai';
import { MdOutlineVerified } from 'react-icons/md'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { CiEdit } from 'react-icons/ci'
import { FiCheck } from 'react-icons/fi'
import { useMessage } from './contexts/MessageContext';
import { fetchUserNFTsMoralis } from './components/moralis';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { fetchDatabaseSpecificIdData, updateDescription, updateOwner, updateTitle, updateSocialMedia, updateImgUrl, updateImgThumb } from "./components/databaseQueries";
import Loader from "react-js-loader";
import { useAccount } from 'wagmi'
import { useContractRead } from 'wagmi'
import { useEnsName } from 'wagmi';


function Dashboard () {
  
  //Contract ABI - Import from contract folders.
  const abi = require('./contracts/ABI.json');

  // Get address from wallet connect
  const { address } = useAccount();

  // Read token ids of user from contract
  const contractRead = useContractRead({
    address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
    abi: abi,
    functionName: 'tokensOfOwner',
    args: [address],
  })

  // Get ENS Name from address
  const { data: ensName } = useEnsName({
    address: address
  });  

  // Gets selected id
  const [fetchIdInfo, setFetchIdInfo] = useState(null);
  // Sets console message
  const { setConsoleMessage } = useMessage();

  // ENS Resolver
  const [fetchEns, setFetchEns] = useState(null);
  useEffect(() => {
    const checkENS = async () => {            
      if (ensName) {                  
          setFetchEns(ensName);        
      }          
    };
    checkENS();        
  }, [address, ensName]);

  // Check if wallet holds token and filter user ids
  const [ownerIds, setOwnerIds] = useState(null);
  const [tokenId, setTokenId] = useState(false);
  useEffect(() => {
    if (contractRead.data) {
      // Check if there are any tokens owned by the user
      if (contractRead.data.length > 0) {
        setTokenId(true);
        // Convert the BigInts to strings and store in ownerIds
        setOwnerIds(contractRead.data.map(num => num.toString()));        
      } else {
        setTokenId(false);
      }
    }  
  }, [contractRead.data, tokenId]);  
  
  // Automatically update owner's wallet/ens
  const [owner, setOwner] = useState('');
  useEffect(() => {
    const writeOwnerToDatabase = async () => {
      if (fetchIdInfo !== null && fetchEns !== null) {
        setOwner(fetchEns);
        await updateOwner(fetchIdInfo, fetchEns);
      } else if (fetchIdInfo !== null && fetchEns == null) {
        setOwner(address);        
        await updateOwner(fetchIdInfo, address);
      }     
    };
    writeOwnerToDatabase();
  }, [address, fetchIdInfo, owner, fetchEns]);
  
  // Get user's ID fields data from Database
  const [fetchDatabaseFields, setfetchDatabaseFields] = useState({});
  const id = fetchIdInfo;
  useEffect(() => {
    if (fetchIdInfo) {
      const fetchData = async () => {
        const fetchedData = await fetchDatabaseSpecificIdData(id);
        setfetchDatabaseFields(fetchedData);
      };  
      fetchData();      
    }
  }, [id, fetchIdInfo, ownerIds]);

  // Automatically fetch one id on the select dropdown
  useEffect(() => {
    if (ownerIds && ownerIds.length > 0) {
      setFetchIdInfo(ownerIds[0]);      
    }    
  }, [ownerIds]);

  // Read Image Profile Picture  
  const [profileImg, setProfileImg] = useState(pfp);
  useEffect(() => {
    if (fetchDatabaseFields && fetchDatabaseFields.imgURL) {
      setProfileImg(fetchDatabaseFields.imgURL);      
    } else {
      setProfileImg(pfp);      
    }       
  }, [fetchDatabaseFields]);

  // Function to set loading on until pfp is loaded
   const [loading, setLoading] = useState(true);
   const [count, setCount] = useState(0);  
   useEffect(() => {
     setTimeout(() => {
       setLoading(false);            
       }, 1500);      
     const intervalId = setInterval(() => {
       setCount(prevCount => prevCount + 1);
     }, 1);      
     return () => {
       clearInterval(intervalId);
     };
    }, []);
    
  //Function to open/close NFTs modal  
  const [nftModal, setNftModal] = useState(false);
  function openNftModal() {
    setNftModal(true);
  }
  function closeNftModal() {
    setNftModal(false);
  }

  // Function to fetch user NFTs metadata
  const [nftMetadata, setNftMetadata] = useState([]);
  useEffect(() => {
    const fetchNFTs = async () => {
      if (address != null) {      
      const nfts = await fetchUserNFTsMoralis(address);
      const metadata = nfts.map((nft) => nft.metadata);
      // console.log("Metadata de NFTs retornados:", metadata);    
      setNftMetadata(metadata);
    }
    };
    fetchNFTs();
  }, [address]);

  const hasNfts = nftMetadata.some((metadata) => metadata && metadata.image);

  const getImageUrl = (url) => {
    if (!url) return "";
  
    if (url.startsWith("ipfs://")) {
      const ipfsHash = url.split("ipfs://")[1];
      return `https://ipfs.io/ipfs/${ipfsHash}`;
    }
  
    return url;
  };  
   
  const handleImageError = (url) => {
    console.log("Error loading image:", url);
    setNftMetadata((prevMetadata) => prevMetadata.filter((metadata) => metadata && metadata.image !== url));
  };

  const handleNftImageClick = async (nftImageUrl) => {    
    await setThumb(null, nftImageUrl);
    setNftModal(false);    
  };

  // Functions to work with paginations in nft modal
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(nftMetadata.length / itemsPerPage);
  const renderNftsForCurrentPage = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const nftsForCurrentPage = nftMetadata.slice(startIndex, endIndex);
  
    return nftsForCurrentPage.map((metadata, index) =>
      metadata && metadata.image ? (
        <img
          className='nft-image'
          key={index}
          src={getImageUrl(metadata.image)}
          alt="NFT"
          onClick={() => handleNftImageClick(getImageUrl(metadata.image))}
          onError={() => handleImageError(metadata.image)}
        />
      ) : null
    );
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };  

  // Functions to handle upload of user's PFP:
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imgTHUMB, updateThumbUrl] = useState('')
  const [imgURL, updateImgURL] = useState('')
  const [isNFT, setIsNFT] = useState(false);

  //File upload
  async function setThumb(e, nftImageUrl) {
    if (!fetchIdInfo) {
      setConsoleMessage({ type: 'error', message: 'Select an id.' });
      return false; 
    }    

    const isUploadedFile = !nftImageUrl;
    let fileImgURL = nftImageUrl ? nftImageUrl : e.target.files[0];    

    if (nftImageUrl) {
      try {
        const response = await fetch(nftImageUrl);
        fileImgURL = await response.blob();
        setIsNFT(true);
      } catch (error) {
        console.error("Error fetching NFT image:", error);
        return;
      }
    }    
    
    if (isUploadedFile) {
      // Split to check if it is a jpg
      const fileExtension = fileImgURL.name.split(".").at(-1);
      const allowedFileTypes = ["jpg"];
      if (!allowedFileTypes.includes(fileExtension)) {
        setConsoleMessage({ type: 'error', message: 'Image must be jpg.' });
        return false;
      }
      // Check is Size is smaller than 205kb
      if (fileImgURL.size > 505000) {
        setConsoleMessage({
          type: 'error',
          message: 'Image must be smaller than 500KB.',
        });
        return false;
      }
    } 
    
  // Resize image to 500x500 (main board img)
    try {         
      Resizer.imageFileResizer(
        fileImgURL,
        500,
        500,
        "WEBP",
        100,
        0,
        (uri) => {
          updateImgURL(uri);                
        },
        "base64"           
      );    

  //Resize image to 80x80 (thumbnail board img)
      Resizer.imageFileResizer(
        fileImgURL,
        100,
        100,
        "WEBP",
        100,
        0,
        (uri) => {
          updateThumbUrl(uri);                
        },
        "base64"
      );
      setIsImageUploaded(true);
  } catch (error) {
        console.log('Error uploading: ', error)         
      }      
  }
  
  const writeImageUrlToDatabase = async () => {       
      if (imgURL !== fetchDatabaseFields.imgURL) {
      await updateImgUrl(id, imgURL, isNFT);
      setfetchDatabaseFields({ ...fetchDatabaseFields, imgURL: imgURL });
      setIsImageUploaded(false);
      } else {
        setConsoleMessage({ type: 'error', message: 'Image cannot be uploaded, please reload the page and try again.' });
      }
    };

  const writeImageThumbToDatabase = async () => {
     if (imgTHUMB !== fetchDatabaseFields.imgTHUMB) {
     await updateImgThumb(id, imgTHUMB, isNFT);     
     } else {
       setConsoleMessage({ type: 'error', message: 'Image thumbnail cannot be uploaded, please reload the page and try again.' });
     }     
  };
  
  // Function to enable/disable title editing
  const [isEditingTitle, setIsEditingTitle] = useState(false);  
  const handleEditTitle = () => {
    if (fetchIdInfo){
    setUserTitleInput(fetchDatabaseFields.title || '');
    setIsEditingTitle(true);
    } else {
      setConsoleMessage({ type: 'error', message: 'Select an id.' });
    }
  };  

  // Function to update title (user's name) on Database
  const [userTitleInput, setUserTitleInput] = useState('');
  const handleTitleUpdate = async () => {
    if (userTitleInput !== fetchDatabaseFields.title) {
    await updateTitle(id, userTitleInput); 
    setfetchDatabaseFields({ ...fetchDatabaseFields, title: userTitleInput });   
    setIsEditingTitle(false);
    setConsoleMessage({type: 'success', message: 'Your name has been updated.'})
    } else {
      setIsEditingTitle(false);
    }
  };

  // Function to enable/disable description editing
  const [isEditingDescription, setIsEditingDescription] = useState(false);  
  const handleEditDescription = () => {
    if (fetchIdInfo){
    setUserDescriptionInput(fetchDatabaseFields.description || '');
    setIsEditingDescription(true);
    } else {
      setConsoleMessage({ type: 'error', message: 'Select an id.' });
    }
  };

  // Function to update user's description on Database
  const [userDescriptionInput, setUserDescriptionInput] = useState('');
  const handleDescriptionUpdate = async () => {
    if (userDescriptionInput !== fetchDatabaseFields.description) {
    await updateDescription(id, userDescriptionInput); 
    setfetchDatabaseFields({ ...fetchDatabaseFields, description: userDescriptionInput });   
    setIsEditingDescription(false);
    setConsoleMessage({type: 'success', message: 'Your description has been updated.'})
    } else {
      setIsEditingDescription(false);
    }
  };

  // Set socials Modal open or closed
  const [modalIsOpen, setIsOpen] = useState(false);  
    function openModal() {       
      setIsOpen(true);
    }  
    function closeModal() {
      setIsOpen(false);
    }
    
  // Function to update user's social media on Database
  const [socialFields, setSocialFields] = useState({});
  const handleUpdateSocialMedia = async () => {
    const updatedFields = {
      link: socialFields.link !== undefined ? socialFields.link : fetchDatabaseFields.link,
      twitter: socialFields.twitter !== undefined ? socialFields.twitter : fetchDatabaseFields.twitter,
      instagram: socialFields.instagram !== undefined ? socialFields.instagram : fetchDatabaseFields.instagram,
      tiktok: socialFields.tiktok !== undefined ? socialFields.tiktok : fetchDatabaseFields.tiktok,
      discord: socialFields.discord !== undefined ? socialFields.discord : fetchDatabaseFields.discord,
      telegram: socialFields.telegram !== undefined ? socialFields.telegram : fetchDatabaseFields.telegram,
      youtube: socialFields.youtube !== undefined ? socialFields.youtube : fetchDatabaseFields.youtube,
      twitch: socialFields.twitch !== undefined ? socialFields.twitch : fetchDatabaseFields.twitch,
      spotify: socialFields.spotify !== undefined ? socialFields.spotify : fetchDatabaseFields.spotify,
      debank: socialFields.debank !== undefined ? socialFields.debank : fetchDatabaseFields.debank,
      opensea: socialFields.opensea !== undefined ? socialFields.opensea : fetchDatabaseFields.opensea,
    };
  
    await updateSocialMedia(id, updatedFields);
    setfetchDatabaseFields({ ...fetchDatabaseFields, ...updatedFields });
    closeModal();
    setConsoleMessage({type: 'success', message: 'Your social media has been updated.'})
  };
  
  // This is the page that will show if the user is not connected
  const connecedtWallet = () => {
    return (
        <div className='dashboard-not-connected'>        
        <div><Link to="/menu">connect wallet</Link></div>
        </div> 
    )     
  }

  // This is the page that will show if the user is connected, and if he is a holder  
  const holdersPage = () => {
    if(tokenId === true && address){
      return (      
        <div className='member-page'>           

          <Link to='/'><img className='menu-logo-img' alt='flex logo' src={flex_logo}/></Link>

          <div className='dashboard-modal'>
            <div className='dashboard-id-selection'>
              ハシ
              <select className='member-page-select-id-input' title='select ID.' onChange={(event) => {setFetchIdInfo(event.target.value);}} autoFocus>
                <option defaultValue>{fetchIdInfo}</option>          
                {ownerIds.map((id) => {return<option key={id}> {id} </option>})}
              </select>
            </div>

            <div className='dashboard-pfp-uploadbutton'>
              <img src={profileImg} className='dashboard-pfp' alt="Member PFP" width="300"></img>
              {isImageUploaded ? (
                <> 
                  <div>
                  <button className='custom-file-upload-confirmation' onClick={()=> {writeImageUrlToDatabase(); writeImageThumbToDatabase();}}><FiCheck/></button>                  
                  </div>                                                       
                </>
                ) : (
                <>   
                    <div className='upload-pfp-buttons'>
                    <input type="file" id="file-input" className='dashboard-upload-img' title='Select an image.' onChange={setThumb} />
                    <MdOutlineVerified className='custom-wallet-upload' title='verify pfp' onClick={openNftModal}/> 
                    <label htmlFor="file-input" className="custom-file-upload">                                      
                      <AiOutlineUpload title='upload image'/>                                        
                    </label>
                    </div>                  
                </>
                )}                          
            </div>          

            <div className='dashboard-name-wallet-div'>
              <div className='dashboard-name-update'>
                {isEditingTitle ? (
                <>
                <input className='input-name-dashboard' defaultValue={fetchDatabaseFields.title} placeholder='name' type='text' maxLength="30"  onChange={(event) => setUserTitleInput(event.target.value)} autoFocus/>                
                <button className='approve-text-edit-button' onClick={handleTitleUpdate}><FiCheck/></button>
                </>
                ) : (
                <>
                <div className='title-not-editing'>{fetchDatabaseFields.title}</div>
                <button className='dashboard-edit-text-button'  title='edit your name' onClick={handleEditTitle}><CiEdit/></button>
                </>
                )}
              </div>

              <div className='dashboard-wallet'>
                { sliceWallet(address) }
              </div>
            </div> 
            
            <div className='dashboard-description'>
              {isEditingDescription ? (
              <>
              <div className='description-editing'>
              <textarea className='input-description-dashboard' defaultValue={fetchDatabaseFields.description} placeholder='bio' type='text' maxLength="150" onChange={(event) => setUserDescriptionInput(event.target.value)} autoFocus/>
              <button className='approve-text-edit-button' onClick={handleDescriptionUpdate}><FiCheck/></button>
              </div>
              </>
              ) : (
              <>
              <div className='description-not-editing'>
              <div className='description-not-editing-text-area'>{fetchDatabaseFields.description}</div>
              <button className='dashboard-edit-text-button'  title='edit your bio' onClick={handleEditDescription}><CiEdit/></button>
              </div>
              </>
              )}            
            </div>

          <div className='dashboard-social-div'>
            <div>
            <IoIosAddCircleOutline className='dashboard-socials-button' cursor='pointer' title='add social media' onClick={openModal}/>
            </div>

            <div className='dashboard-socials'>              
              {fetchDatabaseFields.link && (<a href={`https://${fetchDatabaseFields.link}`} target='_blank' rel="noreferrer">www</a>)}
              {fetchDatabaseFields.twitter && (<a href={`https://www.twitter.com/@${fetchDatabaseFields.twitter}`} target='_blank' rel="noreferrer">twitter</a>)}
              {fetchDatabaseFields.instagram && (<a href={`https://www.instagram.com/${fetchDatabaseFields.instagram}`} target='_blank' rel="noreferrer">instagram</a>)}
              {fetchDatabaseFields.tiktok && (<a href={`https://www.tiktok.com/@${fetchDatabaseFields.tiktok}`} target='_blank' rel="noreferrer">tiktok</a>)}
              {fetchDatabaseFields.discord && (<a href={`https://www.discord.gg/${fetchDatabaseFields.tiktok}`} target='_blank' rel="noreferrer">discord</a>)}
              {fetchDatabaseFields.telegram && (<a href={`https://t.me/${fetchDatabaseFields.telegram}`} target='_blank' rel="noreferrer">telegram</a>)}
              {fetchDatabaseFields.youtube && (<a href={`https://youtube.com/${fetchDatabaseFields.youtube}`} target='_blank' rel="noreferrer">youtube</a>)}
              {fetchDatabaseFields.twitch && (<a href={`https://twitch.tv/${fetchDatabaseFields.twitch}`} target='_blank' rel="noreferrer">twitch</a>)}
              {fetchDatabaseFields.spotify && (<a href={`https://open.spotify.com/${fetchDatabaseFields.spotify}`} target='_blank' rel="noreferrer">spotify</a>)}
              {fetchDatabaseFields.debank && (<a href={`https://debank.com/${fetchDatabaseFields.debank}`} target='_blank' rel="noreferrer">debank</a>)}
              {fetchDatabaseFields.opensea && (<a href={`https://opensea.io/${fetchDatabaseFields.opensea}`} target='_blank' rel="noreferrer">opensea</a>)}
            </div> 
                       
          </div>          
          
        </div> 

        <div className='dashboard-menu'>
           <Link to='/'>home</Link>
           <Link to='/menu'>menu</Link>           
        </div> 

        <Modal closeTimeoutMS={300} isOpen={modalIsOpen} onRequestClose={closeModal} overlayClassName="dashboard-social-modal" className="dashboard-modal-content">  
          <div className='dashboard-modal-title'>social media</div>
          
          <div className='update-link'>  
            https://                    
            <input className='input-name-dashboard' type='text' placeholder='external-link' defaultValue={fetchDatabaseFields.link} onChange={(e) => setSocialFields({ ...socialFields, link: e.target.value })}/>
          </div>           

          <div className='update-twitter'> 
              twitter.com/@              
              <input className='input-name-dashboard' type='text' placeholder='twitter' defaultValue={fetchDatabaseFields.twitter} onChange={(e) => setSocialFields({ ...socialFields, twitter: e.target.value })}/>
          </div>

          <div className='update-instagram'>
            instagram.com/            
            <input className='input-name-dashboard' type='text' placeholder='instagram' defaultValue={fetchDatabaseFields.instagram} onChange={(e) => setSocialFields({ ...socialFields, instagram: e.target.value })}/>
          </div>

          <div className='update-tiktok'>
            tiktok.com/@
            <input className='input-name-dashboard' type='text' placeholder='tiktok' defaultValue={fetchDatabaseFields.tiktok} onChange={(e) => setSocialFields({ ...socialFields, tiktok: e.target.value })}/>
          </div>

          <div className='update-discord'>
            discord.gg/            
            <input className='input-name-dashboard' type='text' placeholder='discord' defaultValue={fetchDatabaseFields.discord} onChange={(e) => setSocialFields({ ...socialFields, discord: e.target.value })}/>
          </div>

          <div className='update-telegram'>
            t.me/            
            <input className='input-name-dashboard' type='text' placeholder='telegram' defaultValue={fetchDatabaseFields.telegram} onChange={(e) => setSocialFields({ ...socialFields, telegram: e.target.value })}/>
          </div>

          <div className='update-youtube'>  
            youtube.com/          
            <input className='input-name-dashboard' type='text' placeholder='youtube' defaultValue={fetchDatabaseFields.youtube} onChange={(e) => setSocialFields({ ...socialFields, youtube: e.target.value })}/>
          </div> 

          <div className='update-twitch'> 
            twitch.tv/           
            <input className='input-name-dashboard' type='text' placeholder='twitch' defaultValue={fetchDatabaseFields.twitch} onChange={(e) => setSocialFields({ ...socialFields, twitch: e.target.value })}/>
          </div>

          <div className='update-spotify'>
            open.spotify.com/            
            <input className='input-name-dashboard' type='text' placeholder='spotify' defaultValue={fetchDatabaseFields.spotify} onChange={(e) => setSocialFields({ ...socialFields, spotify: e.target.value })}/>
          </div>

          <div className='update-debank'> 
            debank.com/           
            <input className='input-name-dashboard' type='text' placeholder='debank' defaultValue={fetchDatabaseFields.debank} onChange={(e) => setSocialFields({ ...socialFields, debank: e.target.value })}/>
          </div>

          <div className='update-opensea'> 
            opensea.io/           
            <input className='input-name-dashboard' type='text' placeholder='opensea' defaultValue={fetchDatabaseFields.opensea} onChange={(e) => setSocialFields({ ...socialFields, opensea: e.target.value })}/>
          </div> 

          <div className='dashboard-modal-save'>            
            <button className='dashboard-modal-save-button' onClick={handleUpdateSocialMedia}>save changes</button>            
          </div>         
        </Modal> 

        <Modal closeTimeoutMS={300} isOpen={nftModal} onRequestClose={closeNftModal} overlayClassName="dashboard-social-modal" className="dashboard-modal-content">  
          <div className='dashboard-modal-title'>Select your desired nft</div>
          
          <div className="user-nfts">
            {hasNfts ? (
              renderNftsForCurrentPage()
            ) : (
              <p>Your wallet doesn't have NFTs available.</p>
            )}
          </div>
          <div className='nft-pagination'>
            <button className='nft-pagination-buttons' onClick={goToPreviousPage} disabled={currentPage === 1}>
              Prev
            </button>
            <span>
              {currentPage} of {totalPages}
            </span>
            <button className='nft-pagination-buttons' onClick={goToNextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>

                  
        </Modal>    
      </div>        
    )
  } else {
    // This is the page that will show if the user is connected, and if he is NOT a holder    
    return (                 
      <div className='not-holder'>        
        <Link to="/menu">Claim a spot to unlock your dashboard.</Link>
    </div>     
    )
  }
};

//View holders page or Connect Wallet page
  return (
    <div className='member-page-wallpaper'>
      {loading ? (
        <div className='loader-dashboard'> 
          <div>{count} ms</div>
          fetching blockchain data                    
          <Loader type="bubble-loop" bgColor={"#a93df7"} title={""} color={'transparent'} size={70} />          
        </div>
        ) : (
        <div className='member-main-div'>          
          {address ? holdersPage() : connecedtWallet()}        
        </div> 
        )}     
    </div>
  );
}

export default Dashboard;