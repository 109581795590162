// Import necessary hooks and libraries
import './style/App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { database } from './Firebase';
import { ref, onValue } from 'firebase/database';
import Modal from "react-modal";
import Loader from "react-js-loader";
import sliceWallet from "./components/SliceWallet";
import { useMessage } from './contexts/MessageContext';
import verified_icon from './images/verified_icon2.png';
import Draggable from "react-draggable";
import flex_logo from './images/flex_logo.png';
import { useAccount, useContractWrite, useWaitForTransaction, useContractRead } from 'wagmi'
import { ethers } from 'ethers';

function Home() {

// Get address from wallet connect
const { address } = useAccount();
//Contract ABI - Import from contract folders.
const abi = require('./contracts/ABI.json');

// Sets Console message
const { consoleMessage, setConsoleMessage } = useMessage();

// Show or hide loader
const [count, setCount] = useState(0);  
useEffect(() => {        
  const intervalId = setInterval(() => {
    setCount(prevCount => prevCount + 1);
  }, 1);      
  return () => {
    clearInterval(intervalId);
  };
 }, []);

const [showLoader, setShowLoader] = useState(false);
const loader = () => {
 if (showLoader === true)
 return (
   <div className='loader-dashboard'>
   <div>{count} ms</div>
   fetching blockchain data 
   <Loader type="bubble-loop" bgColor={"#a93df7"} title={""} color={'transparent'} size={70} />
   </div>
 )
}

  // //Read all ids from Database
  const [getInfo, setInfo] = useState([]);
  useEffect(() =>{  
    setShowLoader(true);
    const dbRef = ref(database, 'id/');
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      const dataArray = Object.keys(data).map((key) => ({
        id: key,
        imgTHUMB: data[key].imgTHUMB, // apenas pegar imgTHUMB
      }));
      setInfo(dataArray);
      setShowLoader(false);
    });
  }, []);

  //Open modal with id, used to change grid's order
  const openModalWithId = (id) => {
    setModalData(id);
    openModal();
  };

  //Set Modal open or closed
  const [modalIsOpen, setIsOpen] = useState(false);  
  //Open Modal
  function openModal() {       
    setIsOpen(true)    
  }
  //Close Modal
  function closeModal() {
    setIsOpen(false);
  } 

  //Receive id from getInfo.map and read specific data from DB.
  const [modalData, setModalData] = useState('');  
  const [readInfo, setReadInfo] = useState([]);  
  const findModalId = useCallback(() => {
    const dbRef = ref(database, `id/${modalData}/details`);
    onValue(
      dbRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {         
          setReadInfo(data);
        }
      },
      (error) => {
        console.error("Error reading data from the database:", error);
      }
    );
  }, [modalData]);
  
  useEffect(() => {
    findModalId();
  }, [modalData, findModalId]);

  //Double tap to enter menu on mobile
  const [goToMenu, setGoToMenu] = useState(false);
  const doubleTap = () => {
    if (goToMenu === true){
      window.location.href = '/menu';
    } else {
      setGoToMenu(true);
      setTimeout(() => {
      setGoToMenu(false);  
      setConsoleMessage({ type: 'error', message: 'Slower.'});
      }, 500);          
    }
  }

  //Show and hide doubleclick and drag tips
  const [showDiv,setShowDiv] = useState(false)
    useEffect(()=>{      
      setTimeout(function() {
        setShowDiv(true)
          }, 2000);       
      setTimeout(function() {
        setShowDiv(false)
          }, 14000);
        },
  []);

//Check if database field is filled to show or not icon on modal
  const checkDBFillInstagram = () => {
    if (readInfo.instagram) {
      return (
        <a href={'https://www.instagram.com/' + readInfo.instagram } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>instagram</div></a>
      )
    } else {
      return false
    }    
  }
  const checkDBFillTiktok = () => {
    if (readInfo.tiktok) {
      return (
        <a href={'https://www.tiktok.com/@' + readInfo.tiktok } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>tiktok</div></a>
      )
    } else {
      return false
    }    
  }
  const checkDBFillTwitter = () => {
    if (readInfo.twitter) {
      return (
        <a href={'https://www.twitter.com/@' + readInfo.twitter } rel="noreferrer" title='twitter' target="_blank"><div className='modal-social-icon-container'>twitter</div></a>
      )
    } else {
      return false
    }    
  }
  const checkDBFillExternalLink = () => {
    if (readInfo.link) {
      return (
        <a href={'https://' + readInfo.link} rel="noreferrer" target='_blank'><div className='modal-social-icon-container'>www</div></a>        
      )
    } else {
      return false
    }    
  }
  const checkDBFillDiscord = () => {
    if (readInfo.discord) {
      return (
        <a href={'https://www.discord.gg/' + readInfo.discord } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>discord</div></a>
      )
    } else {
      return false
    }    
  }
  const checkDBFillTelegram = () => {
    if (readInfo.telegram) {
      return (
        <a href={'https://t.me/' + readInfo.telegram } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>telegram</div></a>        
      )
    } else {
      return false
    }
  }
  const checkDBFillTwitch = () => {
    if (readInfo.twitch) {
      return (
        <a href={'https://www.twitch.tv/' + readInfo.twitch } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>twitch</div> </a>        
      )
    } else {
      return false
    }
  }
  const checkDBFillSpotify = () => {
    if (readInfo.spotify) {
      return (
        <a href={'https://open.spotify.com/' + readInfo.spotify } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>spotify</div></a>        
      )
    } else {
      return false
    }
  }
  const checkDBFillYoutube = () => {
    if (readInfo.youtube) {
      return (
        <a href={'https://www.youtube.com/' + readInfo.youtube } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>youtube</div></a>        
      )
    } else {
      return false
    }
  }

  const checkDBFillOpenSea = () => {
    if (readInfo.opensea) {
      return (
        <a href={'https://opensea.io/' + readInfo.opensea } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>opensea</div></a>        
      )
    } else {
      return false
    }
  }

  const checkDBFillDebank = () => {
    if (readInfo.debank) {
      return (
        <a href={'https://debank.com/' + readInfo.debank } rel="noreferrer" target="_blank"><div className='modal-social-icon-container'>debank</div></a>        
      )
    } else {
      return false
    }
  } 

  // Set promo code if available
  const [promoCode, setPromoCode] = useState("");
  // Set mint value
  const [ mintValue ] = useState(0.05);  

  //Set Modal open or closed
  const [promoModalIsOpen, setpromoModalIsOpen] = useState(false);  
  //Open Modal
  function openPromoModal() {
    if (!address) {        
      setConsoleMessage({ type: 'error', message: 'Please connect your wallet to claim your spot.'});
      navigate("/menu");
      return;
    } 
    setpromoModalIsOpen(true)    
  };
  //Close Modal
  function closePromoModal() {
    setpromoModalIsOpen(false);
  };
  function claimDisabled() {           
      setConsoleMessage({ type: 'error', message: 'Claim is not available yet.'});         
  };
  
  // Read token ids of user from contract  
  const getPromoCode = useContractRead({
    address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
    abi: abi,
    functionName: 'promoCodeToDiscount',
    args: [promoCode],      
  });  
  
  function getDiscount() {        
    const discountValue = (mintValue * getPromoCode.data.toString()) / 100;
    const newMintValue = mintValue - discountValue;    
    if (getPromoCode.data.toString() > 0) {
      setConsoleMessage({ type: 'error', message: `${getPromoCode.data.toString()}% discount applied. Sending transaction...` });
      closePromoModal();
    } else if (promoCode === "") { 
      setConsoleMessage({ type: 'error', message: `Sending transaction...` });
      console.log('No promo code applied.');
      closePromoModal();
    } else {
      setConsoleMessage({ type: 'error', message: 'Promo code is not valid. Sending transaction...' });
      closePromoModal();
    }    
    handleMintButton(newMintValue);
  };

  // Mint arguments
  const { data, write } = useContractWrite({
    address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
    abi: abi,
    functionName: 'mintNFT',    
    chainId: 5,
    onError(error) {          
      if (error.message.includes('Chain mismatch:')) {
        setConsoleMessage({ type: 'error', message: 'Please change to Ethereum network.' });      
      } else if (error.message.includes('User rejected the request')) {          
          setConsoleMessage({ type: 'error', message: 'User rejected the request.' });
        } else if (error.message.includes('The total cost')) {            
            setConsoleMessage({ type: 'error', message: 'Insufficient balance.' });
          } else if (error.message.includes('The contract is paused')) { 
              setConsoleMessage({ type: 'error', message: 'Mint has not started yet.' });
            } else if (error.message.includes('Promo code usage limit exceeded')) { 
                setConsoleMessage({ type: 'error', message: 'Promo code usage limit exceeded.' });
              }
    },    
  });  
    
  //Handle mint NFT button calls
  const navigate = useNavigate();
  async function handleMintButton(newMintValue = mintValue) {    
    try {      
      write({
        args: [modalData, promoCode],
        from: address,
        value: ethers.utils.parseEther(newMintValue.toString()),
      });
    } catch (error) {
      // Handle error here
      console.log(error.message)       
    }
    setPromoCode("");
  } 
    
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  useEffect(() => {
    if (isLoading && data) {
      setShowLoader(true);
      setConsoleMessage({ type: 'success', message: 'Waiting for transaction confirmation.'});      
    } else if (isSuccess && data) {
      setConsoleMessage({ type: 'success', message: 'You flex.'});
      setShowLoader(false);
      navigate("/dashboard");      
    }
  }, [isLoading, data, setConsoleMessage, isSuccess, navigate]);
  

  // Function to check if user pfp is verified
  const isUserVerified = () => {
    if (readInfo.verified === 1) {
      return (
        <img alt='verified' className='verified-icon' title='ownership verified' src={verified_icon}></img>
      )} else {
        return null
      }
    };  

  // Set if user is dragging or not.
  const [isDragging, setIsDragging] = useState(false);   
   
//Home UX
   return (        
    <div className="grid-container">
         {loader()}  
         <Draggable onStart={() => setIsDragging(true)} onStop={() => setIsDragging(false)}>       
          <div className='draggable-div' title='double click or drag' onTouchEndCapture={doubleTap} onDoubleClick={() => (window.location.href = '/menu')}>             
            <img alt='Flex721'  className='draggable-img' title='Menu' src={flex_logo}></img>
            {showDiv? <div className='draggable-text' id='removableDiv'>
              dubclick/drag
            </div>
            :<></>}
          </div>
        </Draggable>

        <div className={`consoleMessage ${consoleMessage.message ? '' : 'hidden'} ${consoleMessage.type}`}>
          {consoleMessage.message}
        </div>

        {isDragging && <div className="cover-div"></div>}

        {getInfo.map((id, value) =>  
          <div className={'grid-img'} 
          onClick={() => openModalWithId(id.id)}               
          title={'ハシ'+id.id}
          key={value.toString()}
          style={{ backgroundImage: 'url(' + id.imgTHUMB + ')'}}>
        </div>         
         )}      
        
        <Modal closeTimeoutMS={300} isOpen={modalIsOpen} onRequestClose={closeModal} overlayClassName="modal-overlay" className="modal-content">
        
          {/*Modal ID*/}  
            <div className='modal-title-vote-container'>
              <div className='modal-title-id'>
                ハシ{modalData}
              </div>           
            </div>     

          {/*Member PFP*/} 
          <div className='user-pfp-div'>
          {isUserVerified()}       
          <img alt="Member PFP" className='modal-pfp' src={readInfo.imgURL}/>
          </div>

          {/*Member name*/}
          <div className='name-wallet'>
          <div className='modal-name' title='name'>
          {readInfo.title} 
          </div>
          
          {/* Wallet/ENS of owner or mint button*/}
            <div className="home-wallet-address-div">
              {readInfo.owner ? (
                <a
                  href={"http://etherscan.io/address/" + readInfo.owner}                
                  title="Owner"
                  rel="noreferrer"
                  target="_blank"
                > 
                  {sliceWallet(readInfo.owner)}
                </a>
              ) : (
                <button title='claim this spot now' className='handleMintButton' onClick={claimDisabled}>
                  claim spot
                </button>                            
              )}
            </div>
            </div>
                
          {/*Set description.*/}
          <div className='modal-description'>{readInfo.description}</div>  

          <div className='modal-social-icons'>
          {/*Show social media*/}
          {checkDBFillExternalLink()}
          {checkDBFillTwitter()}
          {checkDBFillInstagram()} 
          {checkDBFillTiktok()}
          {checkDBFillDiscord()}
          {checkDBFillTelegram()}
          {checkDBFillYoutube()} 
          {checkDBFillTwitch()} 
          {checkDBFillSpotify()}
          {checkDBFillOpenSea()}
          {checkDBFillDebank()}  
          </div>            
                     
        </Modal> 

        <Modal closeTimeoutMS={300} isOpen={promoModalIsOpen} onRequestClose={closePromoModal} overlayClassName="modal-overlay" className="promo-code-modal-content">
          <div className='promo-code-modal-title'>
            Promo Code
          </div>
          <div>
          <input type='text' value={promoCode} onChange={(e) => setPromoCode(e.target.value)}></input>
          </div>          
          <div>            
            <button onClick={getDiscount}>claim</button>
          </div>
          <div className='promo-code-modal-description'>
            Leave it blank if you don't have a code.
          </div>
        </Modal>


    </div>         
  ); 

}


export default Home