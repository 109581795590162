/* src/App.js */
import './style/App.css'
import Modal from "react-modal";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Dashboard from "./Dashboard";
import Home from "./Home";
import Error from "./Error";
import Menu from "./Menu";
import About from "./About";
import Affiliates from "./Affiliates";
import Terms from "./Terms";
import Drops from "./Drops";
import Figma from "./Figma";
import DropOne from "./drops/one";
import { useMessage } from "./contexts/MessageContext";

//Set modal to root (make it work on every page of the app)
Modal.setAppElement("#root");

//Starting app functions
function App() {  

  //Set messages for users
  const { consoleMessage, setConsoleMessage } = useMessage();

//App UX
  return (        
< div className="App">  
  <div className={`consoleMessage ${consoleMessage.message ? '' : 'hidden'} ${consoleMessage.type}`}>
      {consoleMessage.message}
  </div>

  <Router>
    <Routes>
        <Route path="/" element={<Home />} />;
        <Route path="/dashboard" element={<Dashboard />} />;               
        <Route path="/menu" element={<Menu />} />; 
        <Route path="/about" element={<About />} />;        
        <Route path="/affiliates" element={<Affiliates />} />;
        <Route path="/terms" element={<Terms />} />;
        <Route path="/drops" element={<Drops />} />;
        <Route path="/figma" element={<Figma />} />;
        <Route path="*" element={<Error />} />;      

        <Route path="/drops/one" element={<DropOne />} />;
                             
    </Routes>
  </Router>     
  
</div>

  );
}

export default App