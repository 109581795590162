import { get, ref, update } from "firebase/database";
import { ref as sRef, uploadString } from 'firebase/storage';
import { database, storage } from "../Firebase";
import mergeImages from "merge-images";

    // FETCH DATA FUNCTION

    // Get all ids from database
    export async function fetchDatabaseAllIdData() {
        try {
        const dataSnapshot = await get(ref(database, '/id'));
        const data = dataSnapshot.val();
        return data;
        } catch (error) {
        console.log('Error fetching data', error);
        }
    }

    // Get specific id from database:    
    export async function fetchDatabaseSpecificIdData(id) {
        try {
        const dataSnapshot = await get(ref(database, `/id/${id}/details`));
        const data = dataSnapshot.val();
        return data;
        } catch (error) {
        console.log('Error fetching data for ID', error);
        }
    }
    
    // Function to get data for a specific ID and field from the database
    export async function fetchDatabaseSpecificFieldData(id, field) {
        try {
        const dataSnapshot = await get(ref(database, `/id/${id}/details/${field}`));
        const data = dataSnapshot.val();
        return data;
        } catch (error) {
        console.log('Error fetching data for ID and field', error);
        }
    }



    // UPDATE FUNCTION //

    // Function to update owner in Database
    export const updateOwner = async (id, newOwner) => {
        try {
          await update(ref(database, `id/${id}/details`), { owner: newOwner });
        } catch (error) {
          console.error("Error updating owner:", error);
        }
      };
    
    // Function to update title in Database
    export const updateTitle = async (id, newTitle) => {
        try {
          await update(ref(database, `id/${id}/details`), { title: newTitle });
        } catch (error) {
          console.error("Error updating name:", error);
        }
      };

    // Function to update description in Database
    export const updateDescription = async (id, newDescription) => {
        try {
          await update(ref(database, `id/${id}/details`), { description: newDescription });
        } catch (error) {
          console.error("Error updating description:", error);
        }
      };   

    // Function do update all social media in Database
    export const updateSocialMedia = async (id, fieldsToUpdate) => {
        try {
          await update(ref(database, `id/${id}/details`), fieldsToUpdate);
        } catch (error) {
          console.error("Error updating fields:", error);
        }
      };

    // Function to update user's PFP on Database and set verified status
    export const updateImgUrl = async (id, newImgUrl, isNFT) => {
      try {
        await update(ref(database, `id/${id}/details`), { imgURL: newImgUrl });
        await updateVerifiedStatus(id, isNFT);
        mergeImages([newImgUrl, `https://i.ibb.co/BcNxHs6/watermark.png`],
        {crossOrigin: 'Anonymous', width: 500, height: 500})
        .then((b64) => {
          const storageRef = sRef(storage, `/images/${id + '.png'}`);
          uploadString(storageRef, b64, 'data_url').then((snapshot) => {});
        })
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };
    
    // Function to update description in Database
    export const updateImgThumb = async (id, newImgThumb) => {
      try {
        await update(ref(database, `id/${id}`), { imgTHUMB: newImgThumb });          
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };
    
    // Function to update the verified status in Database
    export const updateVerifiedStatus = async (id, isVerified) => {
      try {
        await update(ref(database, `id/${id}/details`), { verified: isVerified ? 1 : 0 });
      } catch (error) {
        console.error("Error updating verified status:", error);
      }
    };