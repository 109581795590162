import './style/App.css';
import flex_logo from './images/flex_logo.png';
import { Link } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import sliceWallet from "./components/SliceWallet"
import { useAccount, useContractRead } from 'wagmi'

function Affiliates () { 
    
    //Get address from wallet connect
    const { address } = useAccount();
    //Check if wallet is connected
    const isConnected = () => {
        if (address) {
            return(
                <div>
                    {isAffiliate()}                    
                </div>
            )            
        } else {
            return (
                <div className='affiliate-main-div'> 
                    <div className='affiliate-menu-button'>
                        <Link to='/menu'>connect wallet</Link>
                    </div>    
                </div>
            )
        }
    }
    //Contract ABI - Import from contract folders.
    const abi = require('./contracts/ABI.json');

    //Read list of affiliates from contract  
    const getAffiliates = useContractRead({
        address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
        abi: abi,
        functionName: 'getAffiliates'            
    });

    const getPromoCodes = useContractRead ({
        address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
        abi: abi,
        functionName: 'affiliateToPromoCode',
        args: [address],
    });

    const getUsageCount = useContractRead ({
        address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
        abi: abi,
        functionName: 'promoCodeUsageCount',
        args: [getPromoCodes.data],
    });
    
    const getDiscount = useContractRead ({
        address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
        abi: abi,
        functionName: 'promoCodeToDiscount',
        args: [getPromoCodes.data],
    });    
    
    const getAffiliateShare = useContractRead ({
        address: '0x460380BF5433090eeDaa5c6C2Cf6262D4c0e4f9A',
        abi: abi,
        functionName: 'promoCodeToPercent',
        args: [getPromoCodes.data],
    });

    const spotCost = 0.05;

    const [affiliateShare, setAffiliateShare] = useState("");
    const getEthCollected = useCallback(() => {
      if (address && affiliates.includes(address) && getAffiliates && getPromoCodes && getUsageCount && getDiscount && getAffiliateShare) {
        const priceAfterDiscount = spotCost - ((spotCost * getDiscount.data.toString()) / 100);
        const affiliateShare = (priceAfterDiscount * getAffiliateShare.data.toString()) / 100;
        const totalAffiliateShare = affiliateShare * getUsageCount.data.toString();        
        setAffiliateShare(totalAffiliateShare.toFixed(4));
      }
    }, [
        getAffiliates, 
        getPromoCodes, 
        getUsageCount, 
        getDiscount, 
        getAffiliateShare, 
        spotCost,         
        address,         
    ]);    
    
    useEffect(() => {
      getEthCollected();
    }, [getEthCollected]);

    //Set a list of affiliates
    const [affiliates, setAffiliates] = useState([]);
    //Check if user connected is affiliate
    const isAffiliate = useCallback(() => {        
        if (affiliates.includes(address)) {
          return (
            <div className='affiliate-main-div'>                 
                <div className='menu-logo-img' alt='logo'>      
                    <img src={flex_logo} width='80' alt='flex721'/>     
                </div> 
                <div className='affiliates-title'>
                    Affiliate Program
                </div>                
                <div className='affiliate-wallet'>
                    {sliceWallet(address)}
                </div>
                <div className='affiliates-promo-code'>
                    Your promo code "{getPromoCodes.data}" has been successfully redeemed {getUsageCount.data.toString()} times. The campaign profit totaling {affiliateShare} eth has been transferred to your wallet. 
                </div>                
                <div className='affiliate-menu-button'>
                    <Link to='/menu'>menu</Link>
                </div>                 
            </div>
          )
        } else {
            return (
                <div className='affiliate-main-div'> 
                    <div className='affiliate-menu-button'>
                        <Link to='/menu'>You are not an affiliate.</Link>
                    </div>                 
                </div>
            )          
        }
    }, [address, affiliates, affiliateShare, getPromoCodes.data, getUsageCount.data]);

    useEffect(() => {
        if (getAffiliates.data) {
          setAffiliates(getAffiliates.data);          
        }
      }, [ affiliateShare, getPromoCodes.data, getUsageCount.data, getAffiliates.data, address, isAffiliate]);      

    // User ux
    return (
        <div className='affiliate-main-div'> 
            {isConnected()}
        </div>
    )
}

export default Affiliates;