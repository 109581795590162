import { createContext, useContext, useState, useEffect } from "react";

const MessageContext = createContext();

export function useMessage() {
  return useContext(MessageContext);
}

export function MessageProvider({ children }) {
  const [consoleMessage, setConsoleMessage] = useState({ type: '', message: '' });

  const value = {
    consoleMessage,
    setConsoleMessage,
  };

useEffect(() => {
  if (consoleMessage.message) {
    const timer = setTimeout(() => {
      setConsoleMessage({ type: '', message: '' });
    }, 5000);
      return () => clearTimeout(timer);
  }
}, [consoleMessage, setConsoleMessage]);


  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
}