import './style/App.css';
import { Link } from "react-router-dom";

function Figma
() {

    return(            
            <div className='figma-wallpaper'>                
                <iframe                     
                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fkh9Ouq6aZaMejXKSXW6NOs%2FWelcome-to-Flex721%3Ftype%3Dwhiteboard%26node-id%3D0%253A1%26t%3DvBEYFyb9Q1jlmnrK-1">
                </iframe>       
                {/* <div className='figma-back-button-div'>
                    <Link to="/menu" className='figma-back-button'>menu</Link>
                </div>*/}
            </div>
        )
}

export default Figma