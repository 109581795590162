import Moralis from 'moralis';

let moralisInitialized = false;

const getMoralisInstance = async () => {
  if (!moralisInitialized) {
    try {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS_API_KEY,
      });      
      moralisInitialized = true;
    } catch (e) {
      console.error(e);
    }
  }

  return Moralis;
};

const fetchUserNFTsMoralis = async (address) => {
    try {
      const MoralisInstance = await getMoralisInstance();
  
      const response = await MoralisInstance.EvmApi.nft.getWalletNFTs({
        chain: "0x5",
        format: "decimal",
        normalizeMetadata: true,
        tokenAddresses: [],
        mediaItems: false,
        address,
      });
  
      const nfts = response.result;
      return nfts;
    } catch (e) {
      console.error(e);
      return [];
    }
  };  

export { fetchUserNFTsMoralis };