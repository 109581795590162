import React from 'react';
import './style/App.css';
import flex_logo from './images/flex_logo.png';
import { Link, useNavigate } from "react-router-dom";
import { useMessage } from './contexts/MessageContext';

//Drops thumbs
import img1 from './drops/images/one-thumb.jpg';
import generalThumb from './drops/images/general-thumb.png';
import generalThumb2 from './drops/images/general-thumb2.png';
import generalThumb3 from './drops/images/general-thumb3.png';
import hourGlass from './drops/images/hour-glass.gif';

function Drops() {

    // Sets console message
    const { setConsoleMessage } = useMessage();

    const navigate = useNavigate();

    const links = [
        { path: '/drops/one', available: true, img: img1 }, 
        { path: '/drops/two', available: false, img: generalThumb3 },
        { path: '/drops/three', available: false, img: generalThumb3 },
        { path: '/drops/four', available: false, img: generalThumb3 },
        { path: '/drops/five', available: false, img: generalThumb3 },
        { path: '/drops/six', available: false, img: generalThumb3 },
        { path: '/drops/seven', available: false, img: generalThumb3 },
        { path: '/drops/eight', available: false, img: generalThumb3 },
        { path: '/drops/nine', available: false, img: generalThumb3 },
        { path: '/drops/ten', available: false, img: generalThumb3 },
        { path: '/drops/eleven', available: false, img: generalThumb3 },
        { path: '/drops/twelve', available: false, img: generalThumb3 },
    ];

    const handleClick = (link, e) => {
        if (!link.available) {
            e.preventDefault();
            setConsoleMessage({ type: 'error', message: 'To be revealed.' });
        } else {
            navigate(link.path);
        }
    }

    return(            
        <div className='drops-wallpaper'>
            <div className='drops-main-div'>
                <div className='drops-content-div'> 
                    <img src={flex_logo} width='80' alt='flex721'/>                                  
                </div>

                <div className='drops-grid'>
                    {links.map((link, index) => (
                        <div 
                            key={index} 
                            onClick={(e) => handleClick(link, e)} 
                            className="product-link" 
                            style={{ backgroundImage: `url(${link.img})` }}
                        />
                    ))}
                </div>

                <div>
                    <Link to="/menu" className='about-back-button'>menu</Link>
                </div> 
            </div>                       
        </div>
    )
}

export default Drops;
