function sliceWallet(address) {
    if (address === null) {
      return false;
    }     
    if (address.includes(".")) {
      return address;
    } else {
      return address.slice(0, 5) + "..." + address.slice(-4);
    }
  }
    
  export default sliceWallet;
  