import React, { useState } from 'react';
import '../style/App.css';
import flex_logo from '../images/flex_logo.png';
import { Link } from "react-router-dom";
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import Modal from "react-modal";
import one_01 from './images/one-01.png';
import one_02 from './images/one-02.png';
import one_03 from './images/one-03.png';

function Product01() {
    const images = [one_01, one_02, one_03];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const nextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
    };

    const previousImage = () => {
        setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (            
        <div className='products-wallpaper'>
            <div className='products-main-div'>
                <img src={flex_logo} width='80' alt='flex721'/>                 
                <div className='products-content-div'>                    
                    <div className='product-image-button'>
                        <div className='product-image-div' onClick={openModal}>
                            <img className='product-image' src={images[currentImageIndex]} alt="product"></img>
                        </div> 
                        <div>
                            <button onClick={previousImage}><AiFillCaretLeft/></button>
                            <button onClick={nextImage}><AiFillCaretRight/></button>
                        </div>                        
                    </div>
                    <div className='product-info'>
                        <div><b>Drop Id:</b> 01</div>                        
                        <div><b>Name:</b> Bills Canon</div>                        
                        <div><b>Claim Method:</b> Raffle</div>                        
                        <div><b>Owner:</b> T.B.A.</div>
                        <div className='product-bio'>Step into the unknown, where extravagance meets playfulness, bound by an idea of value. Every shred carries a tribute to web3 personalities.</div>
                    </div>
                </div>
                <div>
                    <Link to="/drops" className='about-back-button'>back</Link>
                </div>
            </div> 
            <Modal 
                isOpen={modalIsOpen}
                onRequestClose={closeModal}                
                className="product-modal-content"
                overlayClassName="product-modal-overlay"
            >
                <img src={images[currentImageIndex]} alt="product" style={{ width: '100%' }} />
            </Modal>                                  
        </div>
    );
}

export default Product01;
