import React from 'react';
import ReactDOM  from 'react-dom/client';
import './polyfills';
import './style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MessageProvider } from "./contexts/MessageContext";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { mainnet, goerli } from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'

console.log(`

//...............................................
//.██████████.████.......███████████.████..████..
//.██████████.████.......███████████..████████...
//.████.......████.......████..........██████....
//.████.......████.......████...........████.....
//.█████████..████.......██████████.....████.....
//.█████████..████.......██████████.....████......
//.████.......████.......████..........██████...
//.████.......██████████.███████████..████████...
//.████.......██████████.███████████.████..████..
//..........................................721..
//..............do.not.trust.verify..............
`)

const chains = [mainnet, goerli]
const projectId = 'c1d0eda73e46b7ad431cf8573b5e70e2'

const { publicClient } = configureChains(chains, 
  [w3mProvider({ projectId })],
  [infuraProvider({ apiKey: 'wss://goerli.infura.io/ws/v3/d7551d85ff7a4f89860d9c51b77906ff' })]
  )
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),  
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>      
      
        <WagmiConfig config={wagmiConfig}>        
        <MessageProvider>              
          <App />                
        </MessageProvider>
        <Web3Modal 
          projectId={projectId} 
          ethereumClient={ethereumClient}
          themeVariables={{
            '--w3m-font-family': 'Courier New, Courier, monospace',
            '--w3m-accent-color': '#a93df7',
            '--w3m-background-color': '#a93df7',
            '--w3m-background-border-radius': '0px',
            '--w3m-container-border-radius': '0px',
            '--w3m-button-hover-highlight-border-radius': '0rem',
            '--w3m-icon-button-border-radius': '0%',
            '--w3m-secondary-button-border-radius': '0%',
            '--w3m-button-border-radius': '0px',            
            // '--w3m-logo-image-url': 'https://i.ibb.co/CH9FFL0/flex-logo.png',
          }} 
        />              
        </WagmiConfig>                             
    
    // </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();