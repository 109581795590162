import './style/App.css';
import flex_logo from './images/flex_logo.png';
import { Link } from "react-router-dom";

function Terms() {

    return(            
            <div className='terms-wallpaper'>
            <div className='terms-main-div'>
                <div className='terms-content-div'>                    
                    <a href='./'><img src={flex_logo} className='about-logo' width='150' alt='Flex 721'/></a>
                    <div className='terms-intro'>
                        <p>Flex721 is a unique non-fungible tokens (NFT) collection functioning on the Ethereum blockchain. This website merely provides an interface for participants to mint and showcase these digital collectibles. As users, you bear full responsibility for maintaining and securing your private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Since the Flex721 smart contract operates on the Ethereum network, transactions are irreversible.</p>                        
                        <p>This website and its associated services are provided "as is" and "as available," with no warranties. By using this website, you accept full responsibility for any transactions involving Flex721 digital collectibles.</p>                        
                        <p>i. NFT Ownership: Each Flex721 spot is an NFT on the Ethereum blockchain. Upon purchasing an NFT, you acquire total ownership of the underlying spot. The Smart Contract and Ethereum Network solely mediate this ownership. At no point can we intervene, freeze, or alter the ownership of any spot.</p>                        
                        <p>ii. Personal Use: Given your continued adherence to these Terms, Flex721 authorizes you a global, royalty-free license to use the purchased NFT, and any extensions you opt to develop or utilize, specifically for personal, non-commercial use; as part of a marketplace that allows buying and selling your NFT.</p>                        
                        <p>iii. Commercial Use: Subject to your sustained compliance with these Terms, Flex721 grants you an unrestricted, worldwide license to use, copy, and display the purchased spot for creating derivative works based on the NFT ("Commercial Use").</p>
                    </div>                   
                </div>
                <div>
                    <Link to="/about" className='about-back-button'>back</Link>
                </div>                            
            </div>                       
            </div>
        )
}

export default Terms