import './style/App.css';
import flex_logo from './images/flex_logo.png';
import { Link } from "react-router-dom";

function Flex() {

    return(            
            <div className='information-wallpaper'>
            <div className='information-main-div'>
                <div className='information-content-div'>                    
                    <a href='./'><img src={flex_logo} className='about-logo' width='150' alt='Flex 721'/></a>
                    <div className='information-intro'>
                        <p>950 spots forming the most exclusive board of the Ethereum blockchain. Each spot is directly linked to a non-fungible token.</p> 
                        <br/>
                        <p>Upon claiming ownership of a spot, the member is part of the Flex community and gain access to past and upcoming features exclusively 
                           to Flex members. That includes your own spot on our board, and access to the most eccentric drops.</p>
                        <br/>
                        <p>Read our <u><Link to="/terms" className='about-back-button'>terms</Link></u> here.</p>
                        <br/>
                        <p>For further information about the project access our <u><a href='https://flex721.gitbook.io/docs/' rel="noreferrer" target='_blank'>gitbook</a></u> documentation.</p>                        
                    </div>                   
                </div>
                <div>
                    <Link to="/menu" className='about-back-button'>menu</Link>
                </div>                            
            </div>                       
            </div>
        )
}

export default Flex